import jwtDecode from 'jwt-decode'
import { observable, flow, action } from 'mobx'
import storage from 'lib/storage'
import { authApi, eventsAuthApi } from 'api'
import { CommonStore } from 'stores/common'
import { tokenNoEventAccess } from '../../testing/mock-data'

export const AuthStore = () => {
  const refreshToken = flow(function* () {
    if (storage.authToken) {
      yield eventsAuthApi.refresh().catch(async (err) => {
        if (err.status === 401) {
          await this.logout()
        }
        throw err
      })
    }
  })
  const refreshTokenIfNeeded = flow(function* () {
    if (storage.authToken) {
      const authToken = jwtDecode(storage.authToken)
      const issued = new Date(authToken.iat * 1000)
      const expires = new Date(authToken.exp * 1000)
      const currentDate = new Date()
      const shouldRefreshToken = issued > currentDate || expires < currentDate || !('ppv_events' in authToken.data)
      if (shouldRefreshToken) {
        yield this.refreshToken()
      }
    }
  })
  const signup = flow(function* (email, password) {
    const response = yield this.api.signup({ email, password })
    storage.authToken = response.token
    storage.userType = 'new-account'
    yield this.refreshToken()
    this.loggedIn = true
    return response
  })
  const login = flow(function* (email, password) {
    const response = yield this.api.login({ email, password })
    storage.authToken = response.token
    storage.userType = 'existing'
    yield this.refreshToken()
    this.loggedIn = true
    return response
  })
  const logout = action(function () {
    storage.authToken = null
    storage.userAccount = null
    storage.userType = null
    this.loggedIn = false
  })
  const heartbeat = flow(function* () {
    const response = yield this.api.heartbeat()
    return response
  })
  const forgotPassword = flow(function* (email) {
    const response = yield this.api.forgotPassword({ email })
    this.email = email
    return response
  })
  const resetPassword = flow(function* (token, password) {
    const response = yield this.api.resetPassword({ token, password })
    storage.authToken = response.token
    yield this.refreshToken()
    this.loggedIn = true
    return response
  })

  const hasEventAccess = (eventId) => {
    if (!storage.authToken) return false
    const events = jwtDecode(storage.authToken).data?.ppv_events ?? []
    return events.includes(eventId)
  }
  const setError = action(function (error = null) {
    this.error = error
  })

  return observable({
    ...CommonStore,
    api: authApi,
    email: null,
    error: null,
    refreshToken,
    refreshTokenIfNeeded,
    signup,
    login,
    logout,
    heartbeat,
    forgotPassword,
    resetPassword,
    loggedIn: !!storage.authToken,
    hasEventAccess,
    get userId() {
      if (!this.loggedIn) return null
      return jwtDecode(storage.authToken).data.id
    },
    get eventIds() {
      if (!this.loggedIn) return []
      return jwtDecode(storage.authToken).data.ppv_events
    },
    setError
  })
}

AuthStore.cacheKey = 'AuthStore'

export default AuthStore
