import { UnionResource } from 'api/resource'

class AuthApi extends UnionResource {
  constructor() {
    super('auth')
  }
  async signup(data) {
    const result = await this.post({ url: `/${this.resource}/signup`, data })
    return result
  }
  async login(data) {
    const result = await this.post({ url: `/${this.resource}/login`, data })
    return result
  }

  async forgotPassword({ email }) {
    const result = await this.post({
      url: `/${this.resource}/forgot-password`,
      data: { email }
    })
    return result
  }

  async resetPassword({ token, password }) {
    const result = await this.post({
      url: `/${this.resource}/reset-password`,
      data: { password },
      headers: { Authorization: `Bearer ${token}` }
    })
    return result
  }

  async heartbeat() {
    return await this.post({ url: `/${this.resource}/heartbeat` })
  }
}

export default AuthApi
